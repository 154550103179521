import React from 'react'
import './links.css';
import Logo from '../../assets/imgs/logoBranca.png';
import PerfilImg from '../../assets/imgs/anaAzul.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faHandPointer, faHeadphonesAlt, faEnvelope, faBullseye, faUserFriends, faBook, faGlobeOceania} from '@fortawesome/free-solid-svg-icons';

const Links = () => {
  return (
    <div className='container-links'>
      <header className='links-header'>

      </header>

      <section className='body-links'>
        <img src={PerfilImg} alt='Foto' />
        <h1>Ana Paula Bacelar</h1>
        <p>Idealizadora Positive Group</p>
        <div className='links'>
          <Link to='https://wa.me/message/JBZ56JONWXKPD1' target="_blank">
            <FontAwesomeIcon icon={faHeadphonesAlt} className='i-link'/>
            Atendimento Positive Group
            <FontAwesomeIcon icon={faHandPointer} className='i-link2'/>
          </Link>
          <Link to='https://positiveempresarial.com.br/jornada-empresarial' target="_blank">
            <FontAwesomeIcon icon={faBullseye} className='i-link'/>
            Jornada Empresarial
            <FontAwesomeIcon icon={faHandPointer} className='i-link2'/>
          </Link>
          <Link to='https://planos-mpm.positiveempresarial.com.br/pre-inscricao/' target='_blank'>
            <FontAwesomeIcon icon={faUserFriends} className='i-link'/>
            Movimento Positive-se Mulher
            <FontAwesomeIcon icon={faHandPointer} className='i-link2'/>
          </Link>
          <Link to='https://wa.me/message/55JA72DPZF3XI1' target='_blank'>
            <FontAwesomeIcon icon={faBook} className='i-link'/>
            Comprar Planner
            <FontAwesomeIcon icon={faHandPointer} className='i-link2'/>
          </Link>
          <Link to='https://wa.me/message/55JA72DPZF3XI1' target='_blank'>
            <FontAwesomeIcon icon={faEnvelope} className='i-link'/>
            Contato para Convites
            <FontAwesomeIcon icon={faHandPointer} className='i-link2'/>
          </Link>
          <Link to='https://www.youtube.com/@anapaulabacelar3047' target='_blank'     >
            <FontAwesomeIcon icon={faYoutube} className='i-link'/>
            Youtube
            <FontAwesomeIcon icon={faHandPointer} className='i-link2'/>
          </Link>
          <Link to='https://positiveempresarial.com.br/' target='_blank'     >
            <FontAwesomeIcon icon={faGlobeOceania} className='i-link'/>
            Nosso Site
            <FontAwesomeIcon icon={faHandPointer} className='i-link2'/>
          </Link>
        </div>
      </section>

      <footer className='links-footer'>
        <img src={Logo} alt='Logo' />
      </footer>
    </div>
  )
}

export default Links;
