import React from 'react'
import './jornada.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faBullseye, faUsers, faDiamond, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import PerfilAna from '../../assets/imgs/anaAzul.jpg';

const JornadaEmpresarial = () => {
  return (
    <div>
      <header className='empresarial-header'>
        <p>A imersão acontecerá: </p>
        <div className='data-span'>
          <span>20 e 21</span>
          <span>09</span>
          <span>2024</span>
        </div>
      </header>

      <section className='empresarial-topo'>
        <h3>Jornada</h3>
        <h2>empresarial</h2>
        <p>estratégias - objetivos - metas</p>
        <Link to='https://wa.me/message/JBZ56JONWXKPD1' target="_blank">
          <button>GARANTIR MINHA VAGA</button>
        </Link>
      </section>

      <section className='empresarial-cards'>
        <div>
          <FontAwesomeIcon className='icon' icon={faClock} />
          <p>São 10 horas de conteúdo exclusivo</p>
        </div>
        <div>
          <FontAwesomeIcon className='icon' icon={faBullseye} />
          <p>Mesa estratégica de negócios</p>
        </div>
        <div>
          <FontAwesomeIcon className='icon' icon={faUsers} />
          <p>Exclusiva para grupo seleto de empresários</p>
        </div>
        <div>
          <FontAwesomeIcon className='icon' icon={faDiamond} />
          <p>Mentora  com mais de 10 anos de experiência</p>
        </div>
      </section>

      <section className='empresarial-pilares'>
        <h2>Alavanque os três Pilares do seu Negócio</h2>
        <div className='pilares-cards'>
          <div>
            <h3>Gestão<br></br>Estratégica</h3>
            <ul>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className='icon' />
                Análise do Ambiente
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className='icon' />
                Cultura Empresarial
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className='icon' />
                Tomada de Decisão
              </li>
            </ul>
          </div>
          <div>
            <h3>Objetivos</h3>
            <ul>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className='icon' />
                Estabelecimento de Metas
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className='icon' />
                Metodologias e Ferramentas Aplicáveis
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className='icon' />
                Feedback e Aprendizado
              </li>
            </ul>
          </div>
          <div>
            <h3>Metas</h3>
            <ul>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className='icon' />
                Desdobramento de Metas
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className='icon' />
                Engajamento e envolvimento
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} className='icon' />
                Revisão e Adaptação
              </li>
            </ul>
          </div>
        </div>
        <Link to='https://wa.me/message/JBZ56JONWXKPD1' target="_blank">
          <button>GARANTIR MINHA VAGA</button>
        </Link>
      </section>

      <section className='empresarial-edicoes'>
        <h2>Veja o que dizem aqueles que já participaram da jornada.</h2>

        <iframe src="https://www.youtube.com/embed/-jhQrwWsUmM?si=NycZOyhOHJ6QxYeZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <Link to='https://wa.me/message/JBZ56JONWXKPD1' target="_blank">
          <FontAwesomeIcon icon={faWhatsapp} className='icon' />
        </Link>
      </section>

      <section className='sobre-ana'>
        <div>
          <div>
            <img src={PerfilAna} alt='Ana Paula'></img>
          </div>
          <div>
            <h2>Conheça a Mentora</h2>
            <p>
              Ana Paula Bacelar, uma líder visionária no campo do empreendedorismo, traz consigo uma mistura única de habilidades como administradora especialista em Inovação e Neuromarketing.
              <br></br>
              <br></br>
              Como empresária educacional, ela é uma pioneira na criação de plataformas de desenvolvimento empreendedor feminino. Sua expertise e paixão por capacitar outros empreendedores e empresários a alcançarem seu pleno potencial a destacam como uma mentora e treinadora inspiradora no cenário empresarial.
            </p>
          </div>
        </div>
        <Link to='https://wa.me/message/JBZ56JONWXKPD1' target="_blank">
          <button>GARANTIR MINHA VAGA</button>
        </Link>
      </section>

      <footer className='empresarial-footer'>
        <img src='../logoBranca.png' alt='Logo'></img>
        <Link to='https://www.instagram.com/positive.group?igsh=Z28wemh3NWx6bGM=' target="_blank">
          <FontAwesomeIcon icon={faInstagram} className='icon' />
          Instagram
        </Link>
        <p>©️ 2024 | Todos os direitos reservados Positive Group CNPJ: 20.925.744/0001-24</p>
        <h3>Jornada Empresarial 2024</h3>
      </footer>
    </div>
  )
}

export default JornadaEmpresarial;
